<template>
  <div>
    <template v-if="$route.path==='/businessModule/personnel/labor-contract'">
      <list></list>
    </template>
    <template  v-if="$route.path.includes('/businessModule/personnel/labor-contract/detail')">
      <detail></detail>
    </template>
    <template v-if="$route.path.includes('/businessModule/personnel/labor-contract/edit')">
      <edit></edit>
    </template>
  </div>


</template>

<script>
import  list from "./list"
import  detail from "./detail"
import  edit from "./edit"
export default {
  name: "laborContract",
  components:{
    list,
    edit,
    detail
  },
  data(){
    return{

    }
  }

}
</script>

<style scoped lang="scss">

</style>