<template>
  <div class="labor-contract-wrapper flex">
    <div class="search-content searchBox">
        <div class="search-item searchItem">
          <span>姓名：</span>
          <el-input type="text" v-model="filter.staffName" placeholder="请输入姓名" size="medium" clearable></el-input>
        </div>
        <div class="search-item searchItem">
          <span>审核状态：</span>
          <el-select v-model="filter.status" size="medium">
            <el-option v-for="item in statusArr" :key="item.value" :label="item.label" :value="item.value" ></el-option>
          </el-select>
        </div>
        <div class="search-item searchItem">
          <span>合同主体：</span>
          <el-select v-model="filter.contractsPartId"  size="medium">
            <el-option v-for="contract in contracts" :key="contract.value" :label="contract.text" :value="contract.value" ></el-option>
          </el-select>
        </div>
        <div class="search-item searchItem">
          <div  style="margin-left: 16px">
            <el-button type="primary" class="btn-search" @click="search()" size="medium">查询</el-button>
            <el-button type="primary" class="btn-search" @click="exportData()" size="medium">导出</el-button>
            <el-button  class="btn-reset" @click="resetSearch" size="medium">重置</el-button>
          </div>
        </div>
    </div>
    <div class="table-content inline-flex contentBox">
      <div class="contentTool">
        <el-button @click="addData" type="primary" size="medium">新增</el-button>
      </div>
      <el-table :data="tableData" class="contentTable" size="medium">
        <el-table-column prop="staffName" label="姓名">
          <template slot-scope="scope">
            {{ scope.row.staffName || "无" }}
          </template>
        </el-table-column>
        <el-table-column  label="申请人">
          <template slot-scope="scope">
            {{ scope.row.createBySysUserName ? scope.row.createBySysUserName.username : "无"}}
          </template>
        </el-table-column>
        <el-table-column prop="sysDeptName" label="部门">
          <template slot-scope="scope">
            {{ scope.row.sysDeptName ? scope.row.sysDeptName.deptName : "无" }}
          </template>
        </el-table-column>
        <el-table-column prop="contractType" label="签订类型">
          <template slot-scope="scope">
            {{ scope.row.contractType==1 ? "首签": "续签" }}
          </template>
        </el-table-column>
        <el-table-column prop="contractsPartA" label="合同主体"></el-table-column>
        <el-table-column prop="contractsTerm" label="合同期限"></el-table-column>
        <el-table-column prop="contractEndTime" label="合同到期时间"></el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
                <span class="status" :class=[$reconava.getStatusClass(scope.row.status)] >
                  {{$reconava.getStatusName(scope.row.status)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="resources" label="附件" :width="280">
          <template slot-scope="scope">
            <list-file can-delete :files="scope.row.resources" @delFile="(file)=>delFile(file,scope.row)"></list-file>
          </template>
        </el-table-column>
        <el-table-column label="操作" :width="200">
          <template  slot-scope="scope">
            <span class="mgr10 blue" v-if="scope.row.status!==6" @click="showDetail(scope.row)">查看</span>
            <!--     通过状态的数据可以上传附件         -->
            <span class="mgr10 blue" v-if="scope.row.status===4" title="上传pdf/doc/docx文件">
                <el-upload class="upload-text inline-block" action="/business/process/upload" :show-file-list="false"
                           accept=".pdf,.doc,.docx" :before-upload="(file)=>beforeUpload(file,scope.row)"
                           :http-request="(file)=>uploadFile(file,scope.row)">上传附件</el-upload>
              </span>
            <!--     草稿/驳回状态的数据可以编辑     -->
            <span class="mgr10 blue" v-if="(scope.row.status===6||scope.row.status===3)&&$reconava.isCreatedByCurrentUser(scope.row.createBySysUserName)"  @click="editData(scope.row.id)">编辑</span>
            <!--     提交但是还没被审核的数据可以撤销        -->
            <span class="mgr10 blue" v-if="scope.row.status===102&&$reconava.isCreatedByCurrentUser(scope.row.createBySysUserName)" @click="repeal(scope.row.id)">撤销</span>
            <!--     驳回的数据可以终止        -->
            <span class="mgr10 blue" v-if="scope.row.status===3&&$reconava.isCreatedByCurrentUser(scope.row.createBySysUserName)" @click="terminate(scope.row.id)">终止</span>
            <!--     审批通过后，可以打印详情           -->
            <span class="mgr10 blue" v-if="scope.row.status===4" @click="_print(scope.row.id)">打印</span>
          </template>
        </el-table-column>
      </el-table>
      <reconova-pagination @paginationChange="paginationChange" :page-no="pageIndex" :page-size="pageSize" :total="total"></reconova-pagination>
    </div>
  </div>
</template>

<script>
import reconovaPagination from "@/components/reconova/reconovaPagination";
import uploadCommon from "@/utils/upload";
import {Loading} from "element-ui";
import {processUpload} from "@/services/check";
import {operatorDialog} from "@/utils/utils";
import Block from "@/components/block";
import ListFile from "@/components/list-file";
import {onDownload} from "@/utils/utils";
export default {
  name: "laborContractList",
  components:{
    ListFile,
    Block,
    reconovaPagination
  },
  data(){
    const columns = [
      {prop:'contractsPartA',label:'合同主体'},
      {prop:'contractsTerm',label:'合同期限'},
      {prop:'contractEndTime',label:'合同到期时间'},
      {prop:'status',label:'审核状态'},
      {prop:'resources',label:'附件',width:'280'},
      {prop:'operate',label:'操作',width:'200'},
    ]
    return {
      typeId:this.$store.state.PERSONNEL.LABOR_CONTRACT_TYPEID,
      statusArr:[],
      contracts:[],
      tableData:[],
      cols:columns,
      filter:{
        staffName:'',
        status:'',
        contractsPartId:''
      },
      pageSize:10,
      pageIndex:1,
      total:0,
      loadingOption: {
        lock:true,
        text:'文件上传中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      }
    }
  },
  created() {
    this.getAuditStatus();
    this.getContracts();
    this.search();
  },
  methods:{
    // 获取审核状态
    getAuditStatus(){
      this.statusArr = this.$map.getter('auditStatus');
      this.statusArr.unshift({value:'',label:'全部'})
    },

    // 获取劳动合同主体
    getContracts(){
      this.$api.personnel.getContractsList({}).then(response=>{
        this.contracts = response.data;
      })
    },

    getParams(pageSize,pageIndex){
      return {
        ...this.filter,
        pageSize:pageSize,
        pageNum:pageIndex
      }
    },
    // 查询
    search(pageSize=10,pageIndex=1){
      let requestParam = this.getParams(pageSize,pageIndex);
      this.$api.personnel.getLaborCtraList(requestParam).then(response=>{
        this.tableData = response.data.list;
        this.total = response.data.total;
        this.pageIndex = response.data.pageNum;
        this.pageSize = response.data.pageSize;
      })
    },

    paginationChange(type,value){
      let size,index;
      if(type==='pageSize'){
        index = 1;
        size = value;
      }else{
        index = value;
        size = this.pageSize;
      }
      this.search(size,index)
    },

    exportData(){
      this.$api.personnel.laborCtrolExport(this.getParams(10000,1)).then(({headers,data})=>{
        onDownload(headers,data)
      })
    },

    _print(id){
      this.$router.push({path:'/businessModule/personnel/labor-contract/print',query:{id}})
    },

    // 重置
    resetSearch(){
      for(let key in this.filter){
        this.filter[key] = ''
      }
      this.search()
    },

    //  查看详情
    showDetail(data){
      this.$router.push(`/businessModule/personnel/labor-contract/detail/${data.id}`)
    },

    //  编辑
    editData(id){
      this.$router.push(`/businessModule/personnel/labor-contract/edit/${id}`)
    },

    //  新增
    addData(){
      this.$router.push('/businessModule/personnel/labor-contract/edit')
    },

    // 撤销
    repeal(id){
      operatorDialog(this,'理由','撤销',(value)=>{
        let param = {
          id:id,
          typeId:this.typeId,
          reason:value||''
        }
        this.$api.checkapi.repealMyApply(param).then(response=>{
          this.$message.success('已撤销');
          this.search()
        })
      })
    },

    // 终止
    terminate(id){
      operatorDialog(this,'理由','终止',(value)=>{
        let param = {
          id:id,
          typeId:this.typeId,
          reason:value||''
        }
        this.$api.checkapi.terminateMyApply(param).then(response=>{
          this.$message.success('已终止');
          this.search()
        })
      })
    },


  //  文件上传前校验
    beforeUpload(file,dataItem){
      dataItem.resources=dataItem.resources||[];
      if(dataItem.resources.length<5){
        //验证文件格式和大小最大100M
        return uploadCommon.checkFileAndSize(file,100,['pdf','doc','docx']);
      }else{
        this.$message.error("最多上传5个文件");
        return false;
      }
    },

    //  文件上传之后将文件更新到审批数据
    updateFile(dataItem){
      let param = {
        id:dataItem.id,
        resources:dataItem.resources
      }
      this.$api.personnel.updateFileToCtraData(param).then(response=>{

      })
    },

    //  文件上传
    uploadFile(data,dataItem) {
      const file = data.file;
      //上传给后端，拿到url
      let form = new FormData;
      form.append("file",file);
      form.append("biz", this.typeId);
      let loading = Loading.service(this.loadingOption)
      processUpload(form).then(res => {
        loading.close();
        dataItem.resources.push({
          name: res.data.fileName,
          url: res.data.fileUrl,
          size: res.data.fileSize,
          id: res.data.id,
          isPicturePreview:res.data.isPicturePreview
        });
        this.updateFile(dataItem)
      })
    },

    delFile(file,dataItem){
      this.updateFile(dataItem);
      //  删除文件
      for (let i = 0; i < dataItem.resources.length; i++) {
        if (file.name === dataItem.resources[i].name) {
          dataItem.resources.splice(i, 1);
          break
        }
      }
    }
  },
}
</script>

<style scoped lang="scss">
.el-table{
  flex: 1;
  overflow-y: auto;
}
.el-input,.el-select{
  width: 220px;
}
</style>